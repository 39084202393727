import React, { useContext } from 'react'
import LocaleContext from '../context/LocaleContext'
import PageContext from '../context/PageContext'
import { INDEX_PATH } from '../domain/path'
import Layout from '../components/layout/Layout'
import Seo from '../components/layout/Seo'

function ThanksPage() {
    const { locale } = useContext(LocaleContext)

    return (
        <PageContext.Provider
            value={{
                basepath: INDEX_PATH,
                locale,
                localised: true,
            }}
        >
            <Layout>
                <Seo
                    title="Thank You"
                    titleCompany="Telobag Official"
                    relativeUrl="/thanks"
                />
                <div className="mb-6">
                    <h1 className="font-bold text-3xl md:text-6xl mb-3 text-primary">
                        Thank you!
                    </h1>

                    <hr className="border-b border-gainsboro w-10" />
                </div>

                <p>We will get back to you on the next business day.</p>
            </Layout>
        </PageContext.Provider>
    )
}

export default ThanksPage
